.cls-1 {
    fill: none;
}
#demoSVG{
    
}
.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-2,
.cls-20,
.cls-23,
.cls-4,
.cls-6 {
    fill: none;
}

.cls-13,
.cls-18,
.cls-19,
.cls-2,
.cls-6 {
    stroke: #000;
}

.cls-12,
.cls-18,
.cls-2,
.cls-23 {
    stroke-miterlimit: 10;
}

.cls-3 {
    fill: gray;
}

.cls-5 {
    fill: #f0f;
}

.cls-13,
.cls-14,
.cls-15,
.cls-16,
.cls-17,
.cls-19,
.cls-20,
.cls-6 {
    stroke-linecap: round;
    stroke-linejoin: round;
}

.cls-6 {
    stroke-width: 0.48px;
}

.cls-7 {
    fill: red;
}

.cls-8 {
    fill: aqua;
}

.cls-9 {
    fill: blue;
}

.cls-10 {
    fill: #ff0;
}

.cls-11 {
    fill: #767676;
}

.cls-12,
.cls-23 {
    stroke: #1d1d1b;
}

.cls-12,
.cls-13,
.cls-15,
.cls-18,
.cls-20,
.cls-23 {
    stroke-width: 0.5px;
}

.cls-14,
.cls-15 {
    stroke: gray;
}

.cls-14,
.cls-16,
.cls-19 {
    stroke-width: 2px;
}

.cls-16,
.cls-17,
.cls-20 {
    stroke: #ed0754;
}

.cls-21 {
    fill: #fcfcfc;
}

.cls-22 {
    fill: #1d1d1b;
}

.st0 {
    fill: #FFFFFF;
}

.st5 {
    fill: none;
    stroke: #000000;
    stroke-width: 0.48;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st7 {
    stroke: #1D1D1B;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
}

.plot_block:hover,
.plot_block:focus{
    fill: #0bbe9e !important;
    cursor: pointer;
}
.plot_block.active {
    fill: #0bbe9e !important; /* Ensure it remains the same when active */
}

.plot_block {
    fill: #efefef;
}

.no_plot_block {
    fill: #FFFFFF;
}
.svg_container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding-left: 20px;
    padding-bottom: 25px;
}

.filters {
    width: 17%;
    padding-right: 20px;
}

.planSubmit{
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: red;
    cursor: pointer;
    margin-top: 10px;
    color: white;
    
}
.svgImage{
    margin: 5px;
    padding: 10px;
    margin-top: 8rem;
}


.fetch_data {
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.placeholder {
    font-size: 18px;
    color: #888;
}
.fetch_img {
    max-width: 100%;
    height: auto;
}
.plot_img{
    max-width: 100%;
}
.text_data {
    margin-right: 20px;
    width: 81%;
}
.text_data .text_details li{
    border-bottom: 1px solid #efefef;
    padding: 7px 5px;
    margin: 0px 0px;
    display: block;
    font-size: 13px;
    line-height: 17px;
    padding-left: 150px;
    position: relative;
    min-height: 25px;
}

.fetch_data .text_details {
    list-style: none;
    padding: 0;
    margin: 0;
}

.fetch_data .text_details .text_details {
    padding: 7px 5px;
    font-size: 13px;
    line-height: 17px;
    padding-left: 74px;
}
.filters .featureTitle{
    padding: 2px 0px 0px 0px;
    font-size: 17px;
    margin-top: 10px;
}
.filters .featureValue{
    display: block;
    padding-bottom: 1px;
    font-size: 14px;
}
.fetch_data .text_details span {
    padding-right: 20px;
    font-weight: bold;
    position: absolute;
    left: 2px;
}
.data_display{
    display: flex;
    flex-direction: row;
}

.svgCheckBox{
    visibility: visible !important;
    opacity: 1 !important;
    display: inline-block !important; 
    vertical-align: middle;
    width: 20px !important;
    height: 15px !important;
    color: black;
    
}

@media (max-width: 767px) {
    .svgImage {
        /* height: 644px; */
    }
    .svg_container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    /* padding-left: 20px; */
    padding-bottom: 25px;
    flex-direction: column-reverse;
}

.filters {
    width: 100%;
    /* padding-right: 20px; */
}

.fetch_data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(243 243 243);
    margin-bottom: 5px;
}

.text_data {
    width: 100%; 
}

.planSubmit {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: red;
    cursor: pointer;
    /* margin-top: 10px; */
    color: white;
}
.data_display {
    display: block;
    /* flex-direction: column-reverse; */
}
}