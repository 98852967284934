div.menuContainer {
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  position: sticky;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(32px);
}

.menus {
  flex-grow: 0;
  text-align: end;
}

.green {
  /* background: #282626; */
  background-color: transparent;
}

.logo {
  width: 13.2rem;
  /* flex-grow: 1; */
  /* margin: 2px; */
  margin-left: 20px;
}

div.menuContainer a {
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  font-size: 17px;
  margin: 0px 10px;
  /* padding: 9px 5px; */
  position: relative;
  z-index: 0;
  cursor: pointer;
} 

div.borderXwidth a:before,
div.borderXwidth a:after {
  position: absolute;
  width: 0%;
  /* Start with 0 width */
  height: 2px;
  content: '';
  background: #ed2024;
  transition: width 0.6s ease-in-out;
  opacity: 0;
}

div.borderXwidth a:before {
  bottom: 0;
  left: 0;
}

div.borderXwidth a:hover:before {
  width: 100%;
  /* Expand to full text width */
  opacity: 1;
  /* Show the underline */
}

.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* background-image: linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%); */
  /* background-size: 3px 3px; */
  /* z-index: 2; */
}

.banner_text {
  /* text-align: center;  */
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  /* max-width: 400px; */
  width: 100%;
  height: 50%;
  margin-left: 35px;
}

.banner_header {
  /* letter-spacing: 0.2em;  */
  font-size: 4.5rem;
  font-weight: 600;
  color: #FFF;
  margin-bottom: 10px;

}

.banner_sub_header {
  letter-spacing: 0.1rem;
  font-size: 3.5rem;
  font-weight: 600;
  color: #FFF;
  /* margin-top: 0.2rem; */
  line-height: 1.04;
}

.banner_para {
  font-size: 1.2rem;
  font-weight: bold;
  color: #999999;
  margin-top: 1rem;
}


video {
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
}

@media (min-width: 768px) {
  .button-36 {
    padding: 0 2.6rem;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s ease-in forwards;
}

.fade-in-btn {
  opacity: 0;
  /* Start invisible */
  animation: fadeIn 2s ease-in forwards 2s;
  /* 2 seconds for the fade-in animation */
}

.fade-in-para {
  opacity: 0;
  /* Start invisible */
  animation: fadeIn 2s ease-in forwards 1s;
  /* 2 seconds for the fade-in animation */
}

/* Define the keyframes for fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
    /* Start with 0 opacity */
  }

  to {
    opacity: 1;
    /* End with full opacity */
  }
}




.button-36 {
  /* box-shadow: 0 4px 8px rgba(192, 192, 192, 0.2);    */
  background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
  font-size: 1rem;
  margin: 15px;
  padding: 15px 45px;
  text-transform: uppercase;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  border: 0;
  transition: transform 0.3s ease, font-size 0.3s ease, background-position 0.5s ease;
  transform-origin: center;
  cursor: pointer;
  margin-left: 0;
}

.button-36:hover {
  transform: scale(1.1);
  /* font-size: 14px; */
  background-position: right center;
  color: #fff;
}

.body {
  position: relative;
}



@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}