.testi-area3 {
    position: relative;
}

.swiper-pagination {
    position: relative; /* Adjust pagination position */
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Space between the content and pagination */
}

.testi-grid {
    padding: 20px;
    text-align: center;
}

.testi-grid_content {
    margin-top: 15px;
}

.swiper-pagination-bullet {
    background: #333; /* Bullet color */
    opacity: 0.7;
}

.swiper-pagination-bullet-active {
    background: #007bff; /* Active bullet color */
}

.box-img img {
    max-width: 100%;
}

.testi-grid_author img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}
